








import Component, { mixins } from "vue-class-component";
import { LoadingMixin } from "@/mixins/Helpers";
import ProgramDetailsForm from "@/components/programs/DetailsForm.vue";
import ProgramPricingForm from "@/components/programs/PricingForm.vue";
import ProgramPlayers from "@/components/programs/ProgramPlayers.vue";

@Component({
  name: "create-program",
  components: {
    ProgramDetailsForm,
    ProgramPricingForm,
    ProgramPlayers
  }
})
export default class CreateProgram extends mixins(LoadingMixin) {
  private creationSuccessful(): void {
    this.$router.replace("/programs/directory");
  }
}
